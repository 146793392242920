@import './variables';
@import './function';


.mallsListWrapper {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 0.7rem 1.2rem;
  // margin: 0 -1.25rem;

  & .mallNameWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 10px 15px;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,.1);
    &:last-child {
      border:none;
    }
    & .mallInfoWrapper{
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    & .mallInitial {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid $priTabLinkClr;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      text-transform: uppercase;
    }
    & .mallName {
      color: $primaryClr;
      // font-weight: 400;
      // font-size: calc-rem(13);
    }

    & .mallStatusWrapper {
      font-size: calc-rem(12);
      display: none;
      margin-left: 10px;
    }

    &.mallSelected {
      & .mallStatusWrapper {
        display: block;
      }
    }
  }
}
.mallSelected {
  background-color: #f7f8fa;
}


.mallDD{
  color: #fff !important;
  &:after {
    opacity: 1 !important;
    margin-left: 0 !important;
  }
}