@import './mixins';
@import './variables';
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
      $map: map-get($map, $key);
  }
  @return $map;
}

@function calc-rem($target) {
  @return ($target / $text__size--default) * 1rem;
}