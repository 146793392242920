@import './variables';

.swal2-container {
    z-index: 2000;

    .swal2-confirm, .swal2-success-line-long, .swal2-success-line-tip  {
        background-color: $primaryClr !important;
    }
    .swal2-confirm, .swal2-deny, .swal2-cancel {
        background-color: $cancelClr;
        min-width: 90px;
    }

    .swal2-title {
        font-size: 20px;
    }

    .swal2-success-ring {
        border-color: rgba($primaryClr, 0.3) !important;
    }

    .swal2-icon.swal2-info {
        border-color: rgba($cancelClr, 0.3);
        color: $cancelClr;
    }
}