@import "./variables";
@import "./function";
@import "./mixins";
@import "./mallSwitch";
@import "./topLeftBrand";
@import "./login";
@import "./customTab";
@import "./customInput";
@import "./customSection";

/* body {
  color: $priFontClr !important;
} */

/* Inpout  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.swal2-shown .MuiMenu-paper {
  opacity: 0 !important;
}

.kt-subheader__title {
  margin: 0;
  padding: 0 1rem 0 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #434349;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-top: 25px;
}

.kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled .kt-wrapper {
  padding-top: 65px !important;
}

// Changes by Amit
i.fa-space {
  margin-right: 10px;
}
.MuiButtonIconContainer {
  p {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .fa-width {
    min-width: 24px;
  }
  i {
    font-size: 14px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    background-color: #f3f6f9;
    border-color: #f3f6f9 !important;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    transition: all ease 0.3s;
  }
  &.view {
    color: $primaryClr;
    i {
      color: $primaryClr;
    }
  }
  &.edit {
    color: #6993ff !important;
    i {
      color: #6993ff !important;
    }
  }
  &.add-more {
    color: #ffa800;
    i {
      color: #ffa800;
    }
  }
  &.delete {
    color: #f64e60;
    i {
      color: #f64e60;
    }
  }
  &:hover {
    i {
      color: #fff;
      transition: all ease 0.3s;
    }
    &.view {
      i {
        background-color: $primaryClr;
      }
    }
    &.edit {
      i {
        color: #fff !important;
        background-color: #6993ff !important;
      }
    }
    &.add-more {
      i {
        background-color: #ffa800;
      }
    }
    &.delete {
      i {
        background-color: #f64e60;
      }
    }
  }
}
.headingrow {
  b {
    // color: #5867dd;
    margin-bottom: 10px;
    display: block;
    font-size: 14px;
  }
}
.kt-portlet__head-title,
.card-label {
  span.headnumber {
    width: 40px;
    height: 40px;
    background-color: #f2f3f7;
    border: 1px solid #ebedf2;
    // color: #5867dd;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
  }
}
.MuiFormControl-marginZero {
  margin-top: 0 !important;
}
.calendar-button {
  .MuiButtonBase-root {
    &:hover {
      background-color: transparent;
    }
  }
  .MuiIconButton-root {
    padding: 0;
  }
}
.billdateCalendar {
  .calendar-button {
    margin-bottom: 0;
    .MuiIconButton-root {
      padding: 12px;
    }
  }
}
.formStepButtons-edit {
  button.previous-step {
    border: none;
    margin-right: 20px;
    &:focus,
    &:active,
    &:visited {
      background-color: transparent !important;
    }
  }
}
.image-nooverflow {
  label {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.image-upload.errorimage {
  label {
    border: 1px solid #f64e60 !important;
  }
  .upload-image-default {
    color: #f64e60;
    border-color: #f64e60;
  }
}
.upload-image-default {
  color: $primaryClr;
  border: 1px solid $primaryClr;
  border-radius: 6px;
  padding: 6px 8px;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  i {
    margin-right: 5px;
    display: block;
    color: $primaryClr;
  }
}
.multimedia-images {
  margin: 0;
  width: 100%;
  .upload-image-default {
    min-width: 118px;
  }
  .subimages {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 0 0 18.4%;
    max-width: 18.4%;
    min-width: 18.4%;
    margin: 10px 0;
    padding: 0;
    .image_hover {
      margin: 0;
      border: none;
      width: 100%;
      border-radius: 4px;
      max-width: 100%;
      height: 80px;
      &:hover {
        transform: none;
      }
    }
    .closeimage {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #f64e60;
      background-color: #fff;
      border-radius: 3px;
      font-size: 0.7rem;
      width: 15px;
      height: 15px;
      border: 1px solid #f64e60;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        font-size: 9px;
        color: #f64e60;
      }
    }
    &:not(:first-child) {
      margin-left: 2%;
    }
    &:last-child,
    &:nth-last-child(2),
    &:nth-last-child(3) {
      .image-zoom {
        right: 0;
      }
    }
  }
  .image-preview {
    .image-zoom {
      z-index: 99;
      position: absolute;
      width: 350px;
      top: 100%;
      display: none;
      margin-top: 10px;
      &.up {
        top: auto;
        bottom: 100%;
        margin-top: -10px;
      }
    }
  }
  .image_hover:hover {
    & + .image-zoom {
      display: block;
    }
  }
  .multimedia-error {
    display: none;
  }
}
.noHoverImage {
  .image-zoom {
    display: none !important;
  }
}
.image-zoomright {
  .image-upload-edit {
    .image-zoom {
      top: 50%;
      transform: translateY(-50%);
      bottom: auto;
      left: 70%;
    }
  }
}
.image-upload-edit {
  .image-zoom {
    z-index: 99;
    position: absolute;
    width: 350px;
    top: 100%;
    display: none;
    margin-top: 10px;
    &.image-zoomup {
      margin-bottom: 10px;
      bottom: 100%;
      top: auto;
    }
  }
  .image_hover {
    margin: 0;
    border: none;
    border-radius: 4px;
    max-width: 100%;
    &:hover {
      transform: none;
      & + .image-zoom {
        display: block;
      }
    }
  }
}
.image-zoom-edit {
  .image-zoom {
    max-width: inherit;
    max-height: inherit;
  }
}
.image-upload-edit2 {
  max-width: 100px;
  position: relative;
  & + label {
    display: none;
  }
  .closeimage {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #f64e60;
    background-color: #fff;
    border-radius: 3px;
    font-size: 0.7rem;
    width: 15px;
    height: 15px;
    border: 1px solid #f64e60;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 9px;
      color: #f64e60;
    }
  }
}
.multimedia-addmore {
  width: 100%;
  margin-bottom: 10px;
  label {
    color: $primaryClr;
    border: 1px solid $primaryClr;
    border-radius: 6px;
    padding: 6px 12px;
    font-weight: 400;
    cursor: pointer;
    i {
      font-size: 12px;
      margin-right: 5px;
      color: $primaryClr;
    }
  }
}
.multimedia-placeholder-remove {
  svg {
    font-size: 3rem;
    color: $primaryClr;
  }
}
.multimedia-defaultremove {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .image-zoom {
    z-index: 99;
    position: absolute;
    width: 350px;
    top: 100%;
    display: none;
    right: 0;
    margin-top: 10px;
  }
  .catalougeImage {
    border-radius: 4px;
    width: 80px;
    height: 80px;
    &:hover {
      transform: none;
      & + .image-zoom {
        display: block;
      }
    }
  }
  & + .multimedia-thumb {
    display: none;
  }
}
.download-pdf {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.download-pdf-btn {
  cursor: pointer;
  .fa {
    color: #fff;
  }
}
.multimedia-catalouge {
  > div {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    .multimedia-thumb {
      label {
        margin-bottom: 0;
      }
    }
    p {
      margin: 10px 0 0;
    }
  }
}
.multimedia-placeholder {
  height: 100px;
  background-color: #f2f3f7;
  margin: 10px 0;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .upload-image-default {
    min-width: 118px;
  }
}
.addCatalouge {
  .multimedia-placeholder {
    width: calc(100% - 20px);
    margin-left: 10px;
  }
}
.subimages + .multimedia-placeholder,
.multimedia-placeholder-remove + .multimedia-placeholder {
  display: none;
}
.ufyndTextBox_search {
  .fa-search {
    color: #373a3c;
    position: absolute;
    z-index: 9;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
  }
  input {
    padding-left: 40px;
  }
  &.input-group {
    > input.form-control {
      border: 1px solid #e4e6ef;
      border-radius: 5px;
      padding: 12px;
      padding-left: 40px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      min-height: 38px;

      &::-moz-placeholder {
        color: #b5b5c3;
      }
      &:-ms-input-placeholder {
        color: #b5b5c3;
      }
      // Internet Explorer 10+
      &::-webkit-input-placeholder {
        color: #b5b5c3;
      }
    }
  }
}

.sq-ft {
  display: flex;
  align-items: center;
  .form-group {
    flex-basis: 100%;
  }
  span.sq-ft-inner {
    margin-left: 10px;
  }
}
.radio-edit {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h6 {
    margin-bottom: 0;
  }
  .form-check {
    margin-right: 0;
    &:not(:first-child) {
      margin-left: 15px;
    }
    input[type="radio"],
    input[type="checkbox"] {
      margin-right: 5px;
    }
    & + p {
      display: block;
      margin: 10px 0 0;
    }
    .form-check-input {
      & + label {
        padding-left: 0;
      }
    }
  }
  & + p {
    display: block;
    width: 100%;
    margin: 10px 0 0;
  }
  &.radio-height {
    min-height: 38.39px;
  }
}
.tiersApplicable {
  display: flex;
  flex-wrap: wrap;
  h6 {
    width: 100%;
  }
  .radio-edit {
    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }
}
.table-contact {
  p {
    white-space: normal;
    margin-bottom: 0;
    &:not(:first-child) {
      margin-top: 5px;
    }
  }
}
.feedMedia {
  display: flex;
  flex-wrap: wrap;
  .image-upload-edit {
    margin-bottom: 15px;
    &:not(:nth-child(3n + 1)) {
      margin-left: 40px;
    }
  }
  .video-preview {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    &:not(:nth-child(4n + 1)) {
      margin-left: 40px;
    }
  }
}
.requiredField2 {
  .form-label {
    &:after {
      content: " *";
      color: #f64e60;
    }
  }
}
.customCssInputFieldError,
.error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f64e60;
}

.editorHeight {
  height: auto !important;
}

/* Demo3 UI */
.img-responsive {
  max-width: 100%;
  height: auto;
}
.userProfileEdit {
  .navi {
    word-break: break-all;
  }
}
.userProfileEmail {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 166px;
}
.card.card-custom > .card-header {
  align-items: center;
  margin-top: 1.2rem;
}
.card-header {
  .toolBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    > a,
    > button {
      margin-left: auto;
    }

    .btn-primary {
      margin-left: 16px !important;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      padding: 10px 12px;
      white-space: nowrap;
    }
  }
}
.tableList {
  ol,
  ul {
    padding: 15px;
    margin: 0;
  }
}
.tableStatus {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 8px 12px;
  border-radius: 10rem;
  display: flex;
  word-break: initial;
  &.statusRed {
    color: #a94442;
    background-color: #f2dede;
  }
  &.statusGreen {
    color: #224421;
    background-color: #dff0d8;
  }
  &.statusOrange {
    color: #7b6b32;
    background-color: #fae497;
  }
  &.statusPink {
    color: #7b6b32;
    background-color: #e6c0c4;
  }
}
.customBadge {
  &:not(:only-child) {
    margin-top: 10px;
  }
  color: #fff;
  &[style*="background: rgb(255, 255, 0)"] {
    color: #3f4254;
  }
  &[style*="background: rgb(255, 255, 255)"] {
    border: 1px solid #3f4254;
    color: #3f4254;
  }
}

#kt_subheader {
  .subheader-title {
    display: none;
  }
  .breadcrumb {
    > a {
      color: #b5b5c3;
    }
    > li.breadcrumb-item {
      a {
        color: $primaryClr !important;
      }
    }
  }
}
.card-title {
  margin: 0 !important;
  .card-label:empty {
    display: none;
  }
}
.card-body {
  div[role="table"] {
    a,
    .btn-link {
      color: #3f4254;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:hover {
        color: $primaryClr;
        i {
          color: $primaryClr;
        }
      }
    }
  }
  .btn.btn-primary.btn-sm {
    display: flex;
    align-items: center;
  }
}
.form-check-edit.form-check,
.tierCheckbox.form-check {
  padding-left: 0;
  display: flex;
  align-items: center;
  input,
  .form-check-input {
    margin: 0;
  }
}
.form-check-input {
  margin-left: 0;
  & + label {
    padding-left: 20px;
  }
}
.inner-form-group {
  .form-group {
    > * {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .form-label {
    padding-top: 9.45px;
    padding-bottom: 9.45px;
    margin-bottom: 0;
    &:empty {
      display: none;
    }
  }
  .form-group {
    margin-bottom: 0;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    .MuiInput-formControl {
      min-height: 38.39px;
    }
  }
}
.formFlex {
  align-items: center;
  display: flex;
}
.whiteDropdown {
  > div {
    background: #fff;
    border-color: #e4e6ef;
    border-radius: 0.42rem;
    &:hover {
      border-color: #e4e6ef;
    }
  }
}
.deleteSegItem.deleteIconBtnEdit {
  justify-content: flex-start;
  align-items: center;
  .deleteIconBtn {
    position: relative;
    width: 22px;
    height: 22px;
    i {
      font-size: 14px;
    }
  }
}
.form-group.form-groupEdit {
  .row {
    > div {
      label {
        margin-bottom: 0;
      }
      &:first-child {
        display: flex;
        align-items: center;
      }
    }
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.viewDetailsAction {
  // text-align: center;
  i {
    display: block;
    margin-bottom: 2px;
  }
}

.toolBar {
  .ufyndBtn {
    display: flex;
    align-items: center;
  }
}
.ufyndBtn.ufyndBtn_primary {
  i.fa-plus {
    font-size: 12px;
    margin-right: 5px;
  }
}

.switchColor {
  .MuiSwitch-colorSecondary.Mui-checked {
    color: $primaryClr !important;
    & + .MuiSwitch-track {
      background-color: $primaryClr !important;
    }
  }
}

.MuiMenu-list {
  .far,
  .fas {
    width: 30px;
  }
}

.sectionBredcrum.sectionBredcrumEdit {
  align-items: flex-start;
  margin: 0 0 30px;
  .pgBckBtn {
    color: #7e8299 !important;
    background-color: #f3f6f9 !important;
    border-color: #f3f6f9 !important;
    &:hover {
      background-color: #e4e6ef !important;
      border-color: #e4e6ef !important;
    }
  }
}
.formStepWrapper.card-header {
  margin-bottom: 0;
}

.customer-profile {
  .card {
    &:not(:first-child) {
      margin-top: 25px;
    }
    .symbol-label {
      width: 100px;
      height: 100px;
      background-color: #f3f6f9;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 0.42rem;
      position: relative;
      .editavtar {
        position: absolute;
        right: -10px;
        top: -10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.05);
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          font-size: 12px;
          color: #6993ff;
        }
      }
    }
    .innerLabel {
      margin-bottom: 0;
      padding: 6px 0;
    }
  }
}
.mobLabelWrapper .mobLabel {
  display: none;
}
button.btn.btnDanger {
  color: #fff;
  background-color: #f64e60;
  border-color: #f64e60;
  &:hover {
    color: #fff !important;
    background-color: #ee2d41 !important;
    border-color: #ee2d41 !important;
  }
}
.modal .modal-header .close span {
  display: inline-block;
  font-size: 32px;
  font-weight: 100;
  color: #b5b5c3;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.noWrap {
  white-space: normal !important;
}

// comment #kt_aside & #kt_wrapper to enable menu toggle

@media only screen and (min-width: 992px) {
  #kt_aside {
    width: 378px;
    .aside-primary {
      width: 78px;
    }
    .aside-secondary {
      .aside-workspace {
        margin-top: 0 !important;
        width: 300px;
        .aside-menu-wrapper {
          padding-left: 1.25rem !important;
          padding-right: 1.25rem !important;
        }
      }
    }
    .aside-nav {
      padding-right: 0;
      margin: 0;
    }
  }
  #kt_wrapper {
    padding-left: 378px;
  }
}

@media only screen and (max-width: 1024px) {
  .card.card-custom > .card-header:not(.flex-nowrap) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .card.card-custom .card-header .card-title {
    width: 100%;
  }
  .card.card-custom .card-header .toolBar {
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .card.card-custom .card-header .ufyndTextBox_search {
    margin-left: 0;
  }
  .card.card-custom .card-header .toolBar > a,
  .card.card-custom .card-header .toolBar > button {
    margin-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  .header-mobile .logo-default {
    display: none;
  }
  #kt_aside .aside-menu-wrapper {
    padding: 10px 12px !important;
  }
  #kt_aside .aside-menu .menu-nav > .menu-item > .menu-link {
    padding: 10px;
  }
  #kt_aside .aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .card.card-custom > .card-header:not(.flex-nowrap) {
    padding: 15px;
  }
  .card.card-custom .card-header .toolBar {
    flex-wrap: nowrap;
  }
  .card.card-custom .card-header .ufyndTextBox_search {
    width: auto;
    max-width: 140px;
  }
  .card.card-custom .card-header .toolBar .searchDateInput {
    width: 90%;
  }
  .card.card-custom .card-header .toolBar .searchDateInput.dateSearchField {
    width: 73%;
  }
  .card.card-custom .card-header .toolBar .searchDateInput.dateSearchField.bookingSearch {
    width: 40%;
  }
  .card.card-custom .card-header .toolBar .searchDateInput + .ufyndSearchAppend {
    margin-right: 15px;
  }
  .interMenu .interMenuList {
    padding: 0 10px 0;
  }
  .interMenu .interMenuList li.menuItem {
    width: auto;
  }
  .interMenu .interMenuList li.menuItem:first-child a {
    margin-left: 1rem;
  }
  .form-group {
    > [class*="col-"] {
      &:not(:first-of-type) {
        margin-top: 20px;
      }
    }
  }
  .mobEditor {
    padding-right: 10px !important;
  }
  .mob-radio.radio-edit {
    .form-check {
      width: 50%;
      margin-top: 10px;
      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
  .tiersApplicable {
    .radio-edit {
      width: 50%;
      margin-top: 10px;
      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
  .formFlex {
    div:empty {
      display: none;
    }
    > [class*="col-"] {
      &:not(:first-of-type):not(:nth-of-type(2)) {
        margin-top: 15px;
      }
    }
  }
  .chipSection {
    margin-top: 20px;
  }
  .bookingChips {
    min-width: 100px;
  }
  .bookingManagement input.form-control {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .mobHide {
    display: none;
  }
  .multimedia-images .subimages:not(:first-child) {
    margin-left: 0;
  }
  .multimedia-images .subimages:not(:nth-child(3n + 1)) {
    margin-left: 5%;
  }
  .multimedia-images .subimages {
    flex: 0 0 30%;
    max-width: 30%;
    min-width: 30%;
  }
  .multimedia-catalouge .form-control {
    margin: 15px 0;
  }
  .image-upload-edit2 {
    width: 50%;
    max-width: 100%;
  }
  .feedMedia .image-upload-edit:not(:nth-child(3n + 1)) {
    margin-left: 0;
  }
  .mobLabelHide {
    display: none;
  }
  .mobLabelWrapper {
    .mobLabel {
      display: block;
      margin-bottom: 5px;
      font-weight: 500;
    }
    .form-label:empty {
      display: none;
    }
    div:not(.chipSection) {
      margin-top: 0 !important;
    }
  }
}

.rdt_Pagination {
  border-top-style: none !important;
  border-top-width: 0 !important;
  border-top-color: transparent !important;
}

.line-clamp {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hide {
  display: none;
}

.line-clamp:hover + .show-full-text {
  display: inline-block;
  position: absolute;
  min-width: 200px;
  left: 90px;
  z-index: 1;
  background: #1bc5bd;
  line-height: 1.2;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
  color: white;
  text-align: center;
}

.scannedBillImage:hover {
  transform: scale(1.5);
  transition: all 0.2s ease-in;
  position: absolute;
  z-index: 9;
}
.scannedBillImage {
  transition: all 0.2s ease-out;
}

// .trigger-button {
//   display: none;
//   a {
//     position: absolute;
//     top: 25%;
//     bottom: 0;
//     right: 0;
//   }

//   button {
//     padding: 2px 4px;
//   }
// }

.table-action-color {
  color: #1bc5bd;
}
@media all and (min-width: 1200px) and (max-width: 1330px) {
  .card-title {
    max-width: 260px;
  }
  .toolBar .ufyndTextBox_search {
    min-width: 220px !important;
    max-width: 220px !important;
  }
  .toolBar .FilterModalButton + .ml-2 button {
    margin-left: 0 !important;
  }
 .card-header .toolBar .FilterModalButton {
    margin-left: 3px !important;
  }
}

.bread-crumb-disabled {
  color: #b5b5c3 !important;
}
