@import './variables';
@import './function';
/* mall toggler button */
.mallToggler {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #fff !important;
  color: #fff;
  font-size: calc-rem(18);
}
.mallTopLeftName {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 190px;
  padding-right: 10px;

  &:hover {
    color: #fff;
  }
}