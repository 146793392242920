//
// Override global variables
//

// Theme colors
$primary:       									#1BC5BD;
$primary-hover:    									#0BB7AF;
$primary-light:    									#C9F7F5;
$primary-inverse:  									#FFFFFF;

$info:       									    #6993FF;
$info-light:    									#E1E9FF;
$info-hover:    									#4A7DFF;
$info-inverse:  									#FFFFFF;
