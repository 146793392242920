// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

body {
  overflow: auto !important;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

// .nav-link:focus {
//     outline: none;
// }

.form-wrapper .required .col-form-label:after {
  content: " *";
  color: #f64e60;
}

.form-wrapper .required .form-label:after {
  content: " *";
  color: #f64e60;
}

.heading-wrapper {
  min-height: 40px;
  text-transform: uppercase;
}

.dashboard-heading-wrapper {
  min-width: 40%;
}

.rdt_TableCol > div {
  cursor: default;
}

.rdt_TableCol > div:has(span) {
  cursor: pointer;
}

.dashboard-heading-wrapper {
  .dashboard-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    color: #181c32;

    &-desc {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.24;
      color: #616473;
    }
  }

  .last-updated {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.24;
    color: #b5b5c3;
    margin-top: 8px;
    display: inline-block;
  }
}

.card-label-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: #181c32;
  margin-bottom: 0;
}

.highchart-content {
  padding-left: 0;
  padding-right: 0;
}

.chart {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    bottom: 27px;
    right: 23px;
    width: 67px;
    height: 10px;
    background: white;
    z-index: 1;
  }
}

.card-detail-stats {
  flex-direction: row !important;

  .card-left {
    width: 64%;
  }

  .card-right {
    flex-grow: 1;
    border-left: 1px solid #e9e9e9;
    background-color: #fafafa;
    padding: 24px;
  }
}

.chart-header {
  min-height: auto !important;
  padding: 24px 24px 0 !important;
}

.chart-body {
  padding: 24px !important;
}

.chart-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start !important;

  .chart-facts-figures {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;

    .chart-figures {
      font-size: 16px;
      font-weight: 500;
      color: #000;

      &-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 11px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .chart-label {
      font-size: 12px;
      font-weight: 500;
      color: #616473;
    }
  }
}

.stats-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.stats-list-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px 0;

  &:first-of-type {
    padding-top: 16px;
  }
}

.stats-list-heading {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #616473;

  svg {
    margin-right: 10px;
  }
}

.stats-list-desc {
  font-size: 24px;
  font-weight: normal;
  color: #181c32;
  display: flex;
  align-items: center;
}

.percent {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  padding: 4px 8px;
  border-radius: 5px;
  margin-left: 12px;

  &.green {
    color: #32bd72;
    background-color: rgba(50, 189, 114, 0.1);
  }

  &.red {
    color: #bd3232;
    background-color: rgba(189, 50, 50, 0.1);
  }

  &.secondary {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.store {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &-list {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #e9e9e9;
  }
  &-count {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    max-width: 18px;
  }
  &-logo {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: black;
    border-radius: 4px;
    margin: 0 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &-detail {
    display: flex;
    flex-direction: column;
  }
  &-name {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  &-scans {
    font-size: 12px;
    font-weight: 500;
    color: #616473;
  }
}
.see-more {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  color: #1bc5bd;
  padding: 18px 0;
}

.row-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // grid-gap: 24px;
  & .one-third-box {
    width: calc(100% / 3 - 20px);
    margin-top: 20px;

    .box-body {
      width: 100%;
    }
  }
}

.box-body {
  padding: 16px !important;
  width: calc(100% / 5);
  margin: 0 10px;

  &.overall-stats {
    padding: 16px !important;
    width: calc(100% / 5 - 10px);
    margin: 0 5px;
  }
}

.loyalty-box-heading {
  font-size: 14px;
  font-weight: 500;
  color: #616473;
  min-height: 42px;
}

.loyalty-box-number {
  font-size: 32px;
  font-weight: normal;
  color: #181c32;
  margin-top: 16px;
}

.overall-stats .loyalty-box-number {
  font-size: 24px;
}

.loyalty-box-number-2 {
  font-size: 36px;
  font-weight: normal;
  color: #181c32;
  display: flex;
  align-items: center;
}

.date-picker-column {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > div {
    height: 50px;
  }
  .react-daterange-picker__wrapper {
    border: none;
    background-color: white;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus,
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive {
    background-color: #17c191;
    color: white;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: #17c191 !important;
    color: white;
  }

  .react-calendar__tile--active:first-child {
    background-color: #17c191 !important;
    color: white;
  }

  .react-calendar__tile--active:last-child {
    background-color: #17c191 !important;
    color: white;
  }

  .react-calendar__tile--active {
    background: rgba(23, 193, 145, 0.7) !important;
    background: white;
  }
}

.date-picker-wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  height: fit-content;

  i {
    font-size: 10px;
    margin: 0 8px;
  }
}

.last-day-count {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  color: #181c32;
  background-color: #f3f6f9;
  padding: 4px 8px;
  border-radius: 5px;
  margin-right: 8px;
  min-width: 110px;
  text-align: center;
}

.date-selector {
  svg {
    margin-right: 8px;
  }

  .date {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.4;
    color: #181c32;
  }
}

.loyalty-ratio {
  font-size: 12px;
  font-weight: 500;
  color: #616473;
}

.with_char_tracker {
  & textarea {
    height: 250px;
  }

  & input {
    min-height: 50px;
  }

  input + span,
  textarea + span {
    position: absolute;
    right: 1%;
    bottom: 1%;
    color: #b5b5c3;
    .current_count {
      color: #17c191;
    }
  }
}
.store-list-container {
  height: 500px;
  overflow-y: scroll;
}

.icon-search {
  position: absolute;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  width: 16px;
  height: 16px;
}


.date-not-selected {
  cursor: pointer;
  > div {
    position: relative;
    width: 40px;
    height: 38px !important;
    background: #1BC5BD;
    border-radius: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.33331 3.33332C2.96512 3.33332 2.66665 3.63179 2.66665 3.99998V13.3333C2.66665 13.7015 2.96512 14 3.33331 14H12.6666C13.0348 14 13.3333 13.7015 13.3333 13.3333V3.99998C13.3333 3.63179 13.0348 3.33332 12.6666 3.33332H3.33331ZM1.33331 3.99998C1.33331 2.89542 2.22874 1.99998 3.33331 1.99998H12.6666C13.7712 1.99998 14.6666 2.89542 14.6666 3.99998V13.3333C14.6666 14.4379 13.7712 15.3333 12.6666 15.3333H3.33331C2.22874 15.3333 1.33331 14.4379 1.33331 13.3333V3.99998Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.6667 0.666656C11.0349 0.666656 11.3333 0.965133 11.3333 1.33332V3.99999C11.3333 4.36818 11.0349 4.66666 10.6667 4.66666C10.2985 4.66666 10 4.36818 10 3.99999V1.33332C10 0.965133 10.2985 0.666656 10.6667 0.666656Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.33334 0.666656C5.70153 0.666656 6.00001 0.965133 6.00001 1.33332V3.99999C6.00001 4.36818 5.70153 4.66666 5.33334 4.66666C4.96515 4.66666 4.66668 4.36818 4.66668 3.99999V1.33332C4.66668 0.965133 4.96515 0.666656 5.33334 0.666656Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.33331 6.66665C1.33331 6.29846 1.63179 5.99998 1.99998 5.99998H14C14.3682 5.99998 14.6666 6.29846 14.6666 6.66665C14.6666 7.03484 14.3682 7.33332 14 7.33332H1.99998C1.63179 7.33332 1.33331 7.03484 1.33331 6.66665Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  input {
    cursor: pointer;
    display: none;
  }
  fieldset {
    border: none;
  }
}

.date-selected {
  max-width: 80px;
  input {
    padding: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  fieldset {
    border: 1px solid #E4E6EF;
    border-radius: 5px;
    max-height: 43px;
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #E4E6EF !important;
}

.spacing-right8 {
  margin-right: 8px;
}
.spacing-left8 {
  margin-left: 8px;
}
.rdt_TableRow {
  &:last-of-type {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #ebedf3;
  }
}
.rdt_TableCell {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
}

.card-body {
  overflow: hidden;
}

.rdt_TableCol_Sortable {
  font-size: 12px;
  line-height: 16px !important;
  font-weight: 500;
}

.rdt_Pagination {
  margin-top: 20px;
  padding: 0;
}

.footer {
  padding: 28px 0 16px !important;

  .footer-content {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;

    .year {
      color: #B5B5C3;
    }

    .company-name {
      color: #181C32;
    }
  }
}

.bg-icon-wrapper {

  background: #1BC5BD;
  border-radius: 5px;
  width: 40px;
  min-width: 40px !important;
  height: 38px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.filter-applied {
    &:before {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #D5242C;
      top: 10px;
      right: 10px;
    }
  }

  &.filter-banner {
    &.spacing-right {
      margin-right: 16px;
    }
  }
}


// Modal styles
.modal-backdrop {
  &.show {
    opacity: 0.7;
  }
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


//Custom label
.label-container {
  display: block;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0 !important;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .label-name {
    display: inline-block;
    margin: 0 0 0 32px;
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    color: #181C32;
    text-transform: capitalize;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #9E9E9E;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input:checked ~ .checkmark {
    background: #1BC5BD;
    border: 2px solid #1BC5BD;
  }

}

.filter-modal {
  .modal-dialog {
    max-width: 447px;
  }
  .modal-header {
    padding-bottom: 12px;
    padding-top: 24px;

    .modal-title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
  }
  .modal-body {
    padding-top: 16px;
    padding-bottom: 30px;

    .filter-types {
      display: flex;
      align-items: center;
      margin-bottom: 26px;

      .type {
        color: #000;
        background: #EBEDF3;
        border-radius: 5px;
        padding: 8px 12px;
        display: inline-block;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        cursor: pointer;
        margin-right: 16px;

        &.active {
          background: #1BC5BD;
          color: #fff;
        }
      }
    }
  }
  .modal-footer {
    padding-top: 16px;
    padding-bottom: 24px;

    .btn {
      margin: 0;
      padding: 12px 24px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;

      &.clear-filter-btn {
        &:disabled {
          background: #C1C1C1;
          border-color:  #C1C1C1;
        }
      }

      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
}
