.loginContainer {
    display: flex;
    flex: 1;
    > div {
        display: flex;
        flex: 1;
        > div {
            display: flex;
            flex: 1;
        }
    }
    .loginWrapper {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
}
.loginForm {
    width: 400px;
    &.success-message {
      width: 420px;
    }
    .title {
        text-align: center;
        margin-bottom: 50px;
        &+ .alert {
            margin-bottom: 22px;
        }
    }
    .fv-plugins-message-container {
        margin-top: 5px;
        color: #F64E60;
    }
    .kt-login__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            padding: 1rem 2.25rem !important;
        }
        &.centerActions {
            justify-content: center;
            button {
                margin-left: 25px;
            }
        }
    }
}

.password-eye-icon {
  position: absolute !important;
  transform: translate(0px, -50%);
  top: 50%;
  right: 15px;
  cursor: pointer;
  &.active-icon i {
    color: #1BC5BD !important;
    // background: #1BC5BD !important;
  }
}
